import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
 } 
 body, html {
	height: 100%;
	font-family: 'Poppins', sans-serif;
}

a {
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
	outline: none !important;
}

a:hover {
	text-decoration: none;
  color: #6a7dfe;
  color: -webkit-linear-gradient(left, #21d4fd, #b721ff);
  color: -o-linear-gradient(left, #21d4fd, #b721ff);
  color: -moz-linear-gradient(left, #21d4fd, #b721ff);
  color: linear-gradient(left, #21d4fd, #b721ff);
}

.w-25 {
	width: 25% !important
}

.w-50 {
	width: 50% !important
}

.w-75 {
	width: 75% !important
}

.w-100 {
	width: 100% !important
}

.w-auto {
	width: auto !important
}

.h-25 {
	height: 25% !important
}

.h-50 {
	height: 50% !important
}

.h-75 {
	height: 75% !important
}

.h-100 {
	height: 100% !important
}

.h-auto {
	height: auto !important
}

.mw-100 {
	max-width: 100% !important
}

.mh-100 {
	max-height: 100% !important
}

.min-vw-100 {
	min-width: 100vw !important
}

.min-vh-100 {
	min-height: 100vh !important
}

.vw-100 {
	width: 100vw !important
}

.vh-100 {
	height: 100vh !important
}

.m-0 {
	margin: 0 !important
}

.mt-0,
.my-0 {
	margin-top: 0 !important
}

.mr-0,
.mx-0 {
	margin-right: 0 !important
}

.mb-0,
.my-0 {
	margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
	margin-left: 0 !important
}

.m-1 {
	margin: .25rem !important
}

.mt-1,
.my-1 {
	margin-top: .25rem !important
}

.mr-1,
.mx-1 {
	margin-right: .25rem !important
}

.mb-1,
.my-1 {
	margin-bottom: .25rem !important
}

.ml-1,
.mx-1 {
	margin-left: .25rem !important
}

.m-2 {
	margin: .5rem !important
}

.mt-2,
.my-2 {
	margin-top: .5rem !important
}

.mr-2,
.mx-2 {
	margin-right: .5rem !important
}

.mb-2,
.my-2 {
	margin-bottom: .5rem !important
}

.ml-2,
.mx-2 {
	margin-left: .5rem !important
}

.m-3 {
	margin: 1rem !important
}

.mt-3,
.my-3 {
	margin-top: 1rem !important
}

.mr-3,
.mx-3 {
	margin-right: 1rem !important
}

.mb-3,
.my-3 {
	margin-bottom: 1rem !important
}

.ml-3,
.mx-3 {
	margin-left: 1rem !important
}

.m-4 {
	margin: 1.5rem !important
}

.mt-4,
.my-4 {
	margin-top: 1.5rem !important
}

.mr-4,
.mx-4 {
	margin-right: 1.5rem !important
}

.mb-4,
.my-4 {
	margin-bottom: 1.5rem !important
}

.ml-4,
.mx-4 {
	margin-left: 1.5rem !important
}

.m-5 {
	margin: 3rem !important
}

.mt-5,
.my-5 {
	margin-top: 3rem !important
}

.mr-5,
.mx-5 {
	margin-right: 3rem !important
}

.mb-5,
.my-5 {
	margin-bottom: 3rem !important
}

.ml-5,
.mx-5 {
	margin-left: 3rem !important
}

.p-0 {
	padding: 0 !important
}

.pt-0,
.py-0 {
	padding-top: 0 !important
}

.pr-0,
.px-0 {
	padding-right: 0 !important
}

.pb-0,
.py-0 {
	padding-bottom: 0 !important
}

.pl-0,
.px-0 {
	padding-left: 0 !important
}

.p-1 {
	padding: .25rem !important
}

.pt-1,
.py-1 {
	padding-top: .25rem !important
}

.pr-1,
.px-1 {
	padding-right: .25rem !important
}

.pb-1,
.py-1 {
	padding-bottom: .25rem !important
}

.pl-1,
.px-1 {
	padding-left: .25rem !important
}

.p-2 {
	padding: .5rem !important
}

.pt-2,
.py-2 {
	padding-top: .5rem !important
}

.pr-2,
.px-2 {
	padding-right: .5rem !important
}

.pb-2,
.py-2 {
	padding-bottom: .5rem !important
}

.pl-2,
.px-2 {
	padding-left: .5rem !important
}

.p-3 {
	padding: 1rem !important
}

.pt-3,
.py-3 {
	padding-top: 1rem !important
}

.pr-3,
.px-3 {
	padding-right: 1rem !important
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important
}

.pl-3,
.px-3 {
	padding-left: 1rem !important
}

.p-4 {
	padding: 1.5rem !important
}

.pt-4,
.py-4 {
	padding-top: 1.5rem !important
}

.pr-4,
.px-4 {
	padding-right: 1.5rem !important
}

.pb-4,
.py-4 {
	padding-bottom: 1.5rem !important
}

.pl-4,
.px-4 {
	padding-left: 1.5rem !important
}

.p-5 {
	padding: 3rem !important
}

.pt-5,
.py-5 {
	padding-top: 3rem !important
}

.pr-5,
.px-5 {
	padding-right: 3rem !important
}

.pb-5,
.py-5 {
	padding-bottom: 3rem !important
}

.pl-5,
.px-5 {
	padding-left: 3rem !important
}

.modal-dialog-scrollable {
    display: flex;
	max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-box {
	max-height: calc(100vh - 3.5rem);
	overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
	overflow-y: auto;
}

.modal-dialog-centered {
	display: flex;
	align-items: center;
	justify-content: center;
    min-height: calc(100% - 3.5rem);
}

`

export default GlobalStyle