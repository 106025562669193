import axios from "axios";

export const verifyCode = (code) => {

    const body = {code}

    return axios.post(process.env.REACT_APP_BASE_URL + '/oauth/verify-reset-code', body)
    //return axios.post('http://localhost:8082' + '/oauth/verify-reset-code', body)
};

export const changePassword = (code, password) => {
    
    const body = {code, password}

    return axios.post(process.env.REACT_APP_BASE_URL + '/oauth/change-password', body)
    //return axios.post('http://localhost:8082' + '/oauth/change-password', body)
}

export const resetPasswordRequest = (email, tenant, connection) => {

    if (tenant === undefined || tenant === null || tenant === '') {
        tenant = "gaming"
    }
    
    const body = {email, tenant, connection}

    return axios.post(process.env.REACT_APP_BASE_URL + '/oauth/reset-password-request', body)
    //return axios.post('http://localhost:8082' + '/oauth/reset-password-request', body)
}