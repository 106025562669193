import React from "react";
import ReactLoading from 'react-loading';

const Loader1 = () => {
  return (
    <div style={{width : '100%', display: 'flex', justifyContent: 'center', padding: '40px', height: '200px'}}>
      <ReactLoading type={'spinningBubbles'} color={'black'} height={'80px'} width={'80px'}/>
    </div>
  )
}
export default Loader1


