import React, {useContext, useEffect, useState} from 'react'
import styled, {keyframes} from 'styled-components'
import {TenantContext} from "../App"
import {useTranslation} from "react-i18next"
import FormGroup from "../components/forms/FormGroup"
import Input from "../components/forms/FormEl";
import Button from "../components/forms/Button";
import {resetPasswordRequest} from '../actions/resetPassword.action'
import {useLocation} from 'react-router'

const change = keyframes`
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
export const LoginPageStyled = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.background};
  background-size: ${props => props.theme.bgsize};
  animation: ${change} ${props => props.theme.bganimation};
  position: relative;
`
export const ContainerLogin = styled.div`
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
`
export const WrapLogin = styled.div`
  width: 24.375rem;
  background: ${props => props.theme.bgcard};
  border-radius: 0.9rem;
  overflow: hidden;
  color: ${props => props.theme.textcolorcard};
  padding: 4rem 2rem 3rem 2rem;
  animation: 0.5s ${fadeIn} ease-out;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 50%);

  @media only screen and (max-width: 425px) {
    padding: 4rem 1rem 3rem 1rem;
  }

`
export const LoginLogo = styled.div`
  display: block;
  text-align: center;
  margin-bottom: 1rem;
`

const ForgetPasswordPage = (props) => {
  const {info} = useContext(TenantContext)
  const [t] = useTranslation("global")
  const [email, setEmail] = useState('')
  const [connection, setConnection] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [finish, setFinish] = useState(false)
  const [searchParams, setSearchParams] = useState({})

  const location = useLocation()

  useEffect(() => {
    const params = getUrlParams(location.search)
    const search = {
      redirect: params.get('redirect_uri'),
      clientId: params.get('client_id'),
      tenant: params.get('tenant')
    }

    setSearchParams(search)
  }, [])

  function getUrlParams(locationHash) {
    let arr = new Map();
    if (locationHash === '') {
      return arr
    }

    locationHash = locationHash.substring(1)

    locationHash.split('&').map((par, index) => {
      arr.set(par.split('=')[0], par.split('=')[1]);
    })
    return arr
  }

  function sendEmail(e) {
    e.preventDefault();
    if (email.trim() === "") {
      setError('Ingrese un email válido')
      return
    }

    setError("")
    setLoading(true)
    resetPasswordRequest(email, searchParams.tenant, connection).then(res => {
      setLoading(false)
      if (res.status === 200) {
        setFinish(true)
      }
    }).catch(error => {
      setLoading(false)
      setError(error.response.data.message)
    })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendEmail(event)
    }
  }

  return (
    <LoginPageStyled>
      <div style={{width: "100%", margin: "0 auto"}}>
        <ContainerLogin>
          <WrapLogin>
            <LoginLogo>
              <img src={info.logo} alt="" width="200"/>
            </LoginLogo>

            <form style={{width: "100%", marginTop: '0.5rem'}}>
              <div style={{marginBottom: '0.5rem'}}>
                <FormGroup>
                  <label>Ingrese su email</label>
                  <Input style={{marginTop: '0.5rem'}} type='email' placeholder={"Email"} value={email}
                         onChange={(e) => setEmail(e.target.value)}
                         onKeyDown={handleKeyDown}/>
                </FormGroup>
              </div>

              {
                !finish  &&
                <Button variant={'primary'}
                        onClick={(e) => sendEmail(e)}
                        style={{width: '100%', marginTop: "0.5rem"}}
                        disabled={loading}>
                  Enviame un link
                </Button>
              }

              {error.length > 0 &&
                <h6 style={{color: 'red', textAlign: "center", padding: "1rem 0rem"}}>{error}</h6>
              }
              {finish &&
                <h6 style={{color: '#008000', textAlign: "center", padding: "1rem 0rem"}}>
                  Enlance enviado! Verifique su correo
                </h6>
              }
            </form>
          </WrapLogin>
        </ContainerLogin>
      </div>
    </LoginPageStyled>
  )
}

export default ForgetPasswordPage
