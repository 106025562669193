import React, {useContext, useState} from "react"
import styled, {keyframes} from 'styled-components'
import {useTranslation} from "react-i18next"
import {TenantContext} from "../App";
import SignUpComponent from "./blocks/SignUpComponent";
import LoginComponent from "./blocks/LoginComponent";
import Button from "../components/forms/Button";
import queryString from 'query-string'
import { Redirect } from 'react-router-dom'

const change = keyframes`
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const LoginPageStyled = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.background};
  background-size: ${props => props.theme.bgsize};
  animation: ${change} ${props => props.theme.bganimation};
  position: relative;
`
export const ContainerLogin = styled.div`
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
`
export const WrapLogin = styled.div`
  width: 24.375rem;
  background: ${props => props.theme.bgcard};
  border-radius: 0.9rem;
  overflow: hidden;
  color: ${props => props.theme.textcolorcard};
  padding: 4rem 2rem 3rem 2rem;
  animation: 0.5s ${fadeIn} ease-out;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 50%);

  @media only screen and (max-width: 425px) {
    padding: 4rem 1rem 3rem 1rem;
  }

`
export const LoginFormTitle = styled.span`
  display: block;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  color: ${props => props.theme.textcolorcard};
  line-height: 1.2;
  text-align: center;
  margin-bottom: 2rem;
`
export const LoginLogo = styled.div`
  display: block;
  text-align: center;
  margin-bottom: 1rem;
`
export const SignUpButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 0 0;

  div {
    padding: 0 0.5rem;
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    cursor: pointer;
  }
`

const LoginPage = (props) => {

  const {info} = useContext(TenantContext)
  const [t] = useTranslation("global")

  const [showRegister, setShowRegister] = useState(false)

  function registerBlock() {
    setShowRegister(true);
  }

  function loginBlock() {
    setShowRegister(false);
  }

  // useEffect(() => {
  //   i18n.changeLanguage('en')
  // }, [])

  const queryValues = queryString.parse(window.location.search)
  const client_id = queryValues['client_id']

  if (client_id === undefined) {
    return <Redirect to='/' />
  }

  return (
    <LoginPageStyled>
      <div style={{width: "100%", margin: "0 auto"}}>
        <ContainerLogin>
          <WrapLogin>
            <LoginLogo>
              <img src={info.logo} alt="" width="200"/>
            </LoginLogo>
            {
              info.showTitle &&
              <LoginFormTitle>
                {info.name}
              </LoginFormTitle>
            }

            {showRegister ?
              <SignUpComponent/>
              :
              <LoginComponent/>
            }

            {
              info.signup &&
              (showRegister ?
                  <div style={{marginTop: '1rem'}}>
                    <Button
                      onClick={loginBlock}
                      style={{width: '100%', backgroundColor: '#c10000', color: '#ffffff'}}>
                      {t("signin.cancel")}
                    </Button>
                  </div>
                  :
                  <SignUpButton>
                    <label>{t("login.no-account")}</label>
                    <div onClick={registerBlock}>
                      {t("login.register")}
                    </div>
                  </SignUpButton>
              )
            }
          </WrapLogin>
        </ContainerLogin>
      </div>

    </LoginPageStyled>
  )
}

export default LoginPage
