import axios from "axios";

export const loginUser = (username, password, clientId, tenant) => {

  const TOKEN_AUTH_USERNAME = clientId
  const TOKEN_AUTH_PASSWORD = '123456'
  //const TENANT = 'gaming'

  if (tenant === undefined || tenant === null || tenant === '') {
    tenant = "gaming"
  }

  const body = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&grant_type=password&tenant=${tenant}&client_id=${clientId}`;

  let config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(TOKEN_AUTH_USERNAME + ':' + TOKEN_AUTH_PASSWORD)
    }
  };

  return axios.post(process.env.REACT_APP_BASE_URL + '/oauth/token', body, config)
};

