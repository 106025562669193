import { FaApple } from "react-icons/fa"
import styled from "styled-components";
import Button from "./Button";
import { useTranslation } from "react-i18next"

const ButtonLogin = styled(Button)`
  width: 100%;
  background: black !important;
  color: white !important;
`

const AppleLoginButton = ({redirectTo, clientId}) => {
  const [t]=useTranslation("global")

  const goToApple = (e) => {
    e.preventDefault();
    const authorizeEndpoint = process.env.REACT_APP_BASE_URL + '/authorize'
    const state = '8541257830'

    const params = {
      connection: 'apple-id',
      state: state,
      redirect_uri: redirectTo,
      client_id: clientId,
      scope: 'name email'
    }
    const paramString = new URLSearchParams(params)
    window.location.href = `${authorizeEndpoint}?${paramString.toString()}`
  }

  return (
    <div style={{marginTop: "10px", display: "flex"}}>
      <ButtonLogin onClick={goToApple}>
        <FaApple color="white" /> {t("login.login-apple")}
      </ButtonLogin>
    </div>
  )
}

export default AppleLoginButton
