import styled from "styled-components";
import React from "react";

const InputStyled = styled.div`
  width: 100%;
  display: inline-block;
  font-size: 1rem;
  
  input, select{
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #566074;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
    &:hover {
      border-color: #c0c4cc;
    }
    &:focus {
      //komercia
      //border-color: #4429b4;
      border: 2px solid #068eef;
      outline: 0;
      //background-color: #fff;
    } 
    
    &:disabled {
      background: #EBEBE4;
    }
  }
`

const Input = React.forwardRef((props, ref) => {
  return (
    <InputStyled>
      <input ref={ref} {...props} />
    </InputStyled>
  )
})


export default Input
