import React, {useContext} from 'react'
import styled from 'styled-components'
import {TenantContext} from "../App";
import {LoginFormTitle, LoginLogo, WrapLogin } from './LoginPage'

const ErrorContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #E9E9E9;
`
const ErrorSubtitle = styled.span`
  display: flex;
  text-align: center;
  margin-bottom: 1rem;
  justify-content: center;
`

const Error404 = () => {
	const {info} = useContext(TenantContext)

  return (
    <ErrorContainer>
      <WrapLogin>
        <LoginLogo>
          <img src={info.logo} alt="" width="200"/>
        </LoginLogo>
        <LoginFormTitle>
          Ooops!
        </LoginFormTitle>
        <ErrorSubtitle style={{fontSize:'1.2rem'}}>Algo salió mal!</ErrorSubtitle>
        <ErrorSubtitle style={{fontSize:'0.8rem'}}>La página que estas buscando no existe 🚀</ErrorSubtitle>
      </WrapLogin>
    </ErrorContainer>
  )
}

export default Error404