import FormGroup from "../../components/forms/FormGroup";
import Input from "../../components/forms/FormEl";
import Button from "../../components/forms/Button";
import GoogleLoginButton from "../../components/forms/GoogleLoginButton";
import React, {useContext, useEffect, useState} from "react";
import {loginUser} from "../../actions/authentication.action";
import {TenantContext} from "../../App";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";
import {FaEye} from "react-icons/fa";
import AppleLoginButton from "../../components/forms/AppleLoginButton";

const LoginComponent = () => {

  const {info} = useContext(TenantContext)
  const [t] = useTranslation("global")
  const location = useLocation()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordShown, setPasswordShown] = useState(false);

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const [searchParams, setSearchParams] = useState({})

  useEffect(() => {
    const params = getUrlParams(location.search)
    const search = {
      redirect: params.get('redirect_uri'),
      clientId: params.get('client_id'),
      tenant: params.get('tenant')
    }

    setSearchParams(search)
  }, [])

  function getUrlParams(locationHash) {
    let arr = new Map();
    if (locationHash === '') {
      return arr
    }

    locationHash = locationHash.substring(1)

    locationHash.split('&').map((par, index) => {
      arr.set(par.split('=')[0], par.split('=')[1]);
    })
    return arr
  }

  function doLogin(e) {
    e.preventDefault()

    if (username === "" || password === "") {
      setError('Ingrese un usuario y contraseña validos');
    } else {
      setLoading(true);
      loginUser(username, password, searchParams.clientId, searchParams.tenant).then(res => {
        if (res.status === 200) {
          setLoading(false)
          const token = res.data.access_token;
          const refreshToken = res.data.refreshToken;
          //const decodedToken = jwt_decode(token);
          setError("")
          success(token, refreshToken)
        }
      }).catch(err => {
        const errorResponse = err.response
        setLoading(false)
        if (errorResponse.status === 401) {
          setError('Usuario o contraseña incorrectas')
        } else {
          if (errorResponse.data !== undefined) {
            setError(errorResponse.data.error_description)
          } else {
            setError('Hubo un error')
          }
        }
      });
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      doLogin(event)
    }
  }

  function success(token, refreshToken) {
    const defaultUrl = "https://unknown.wgt.pe"
    //default redirect
    let goTo = searchParams.redirect
    if (goTo === undefined) {
      goTo = defaultUrl
    }

    const sanitized = goTo
      .replaceAll('%3A', ':')
      .replaceAll('%2F', '/')
      .replaceAll('%26', '?')
      .replaceAll('%3D', '=')

    window.location.href = sanitized + '#id_token=' + token + '&wsrf=' + refreshToken
  }

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <form style={{width: "100%"}}>

        <div style={{marginBottom: '1rem'}}>
          <FormGroup>
            <label>{t("login.user")}</label>
            <Input type='text' placeholder={t("login.user")} value={username}
                   onChange={(e) => setUsername(e.target.value)}/>
          </FormGroup>

          <FormGroup>
            <label>{t("login.password")}</label>
            <div style={{position: "relative"}}>
              <Input type={passwordShown ? "text" : "password"} placeholder={t("login.password")} value={password}
                     onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown}/>
              <FaEye onClick={togglePassword} style={{
                cursor: "pointer",
                position: "absolute",
                right: "20px",
                top: "8px",
                width: "22px",
                height: "23px"
              }}/>
            </div>
          </FormGroup>
          <div style={{display: "flex", justifyContent: "end"}}>
            <a style={{cursor: "pointer"}} href="/forget-password">{t("login.forget-password")}</a>
          </div>
        </div>

        <Button variant={'primary'}
                onClick={(e) => doLogin(e)}
                style={{width: '100%'}}
                disabled={loading}>
          {t("login.login")}
        </Button>

        <div style={{marginTop: '2rem'}}>
          {
            info.socialGoogle &&
            <div style={{marginBottom: '1rem'}}>
              <GoogleLoginButton
                redirectTo={searchParams.redirect}
                clientId={searchParams.clientId}
              />
            </div>
          }
          {
            // (info.socialApple && (searchParams.clientId === 'y8N9UZMtdGA5XaT79tRvnW675nHdxMMo' || searchParams.clientId === 'DBIgDH2TKoqYpgNMTsd9DBIgDH2TKoqY')) &&
            info.socialApple &&
            <div style={{marginBottom: '1rem'}}>
              <AppleLoginButton
                redirectTo={searchParams.redirect}
                clientId={searchParams.clientId}
              />
            </div>
          }
        </div>
        {error.length > 0 &&
          <h6 style={{color: 'red', textAlign: "center", padding: "1rem 0rem"}}>{error}</h6>
        }
      </form>
    </>
  )
}

export default LoginComponent
