import React from 'react'
import {Route, Switch} from "react-router"
import LoginPage from "./pages/LoginPage"
import ForgetPasswordPage from './pages/ForgetPasswordPage'
import RecoverPasswordPage from './pages/RecoverPasswordPage'
import GlobalStyle from './globalStyles'
import {I18nextProvider} from "react-i18next"
import i18next from "i18next"
import LanguageDetector from 'i18next-browser-languagedetector';

import global_es from "./translations/es/global.json"
import global_en from "./translations/en/global.json"
import global_ch from "./translations/ch/global.json"
import global_fr from "./translations/fr/global.json"
import {ThemeProvider} from "styled-components";
import themes from './themes/themes.json'
import Error404 from './pages/Error404'

const options = {
  // order and from where user language should be detected
  order: ['querystring', 'localStorage', 'sessionStorage', 'navigator', 'cookie', 'path', 'subdomain'],
  // /?lng=es
  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',


  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: {path: '/', sameSite: 'strict'}
}

i18next.use(LanguageDetector).init({

  interpolation: {escapeValue: false},
  detection: options,
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    },
    ch: {
      global: global_ch
    },
    fr: {
      global: global_fr
    }
  }
})

const TenantContext = React.createContext({})

function App() {

  const currentHost = window.location.host
  let info = themes[0] //default theme

  if (currentHost === 'id.wgt.pe') {
    info = themes[1]
  } else if (currentHost === 'id.casinokololi8888.com') {
    info = themes[2]
  } else if (currentHost === 'id.anakel.pe') {
    info = themes[3]
  } else if (currentHost === 'login.asis.pe') {
    info = themes[4]
  } else if (currentHost === 'id.wargos.cloud') {
    info = themes[1]
  } else if (currentHost === 'verdant-squirrel-2c44e8.netlify.app') {
    info = themes[1]
  }

  return (
    <>
      <I18nextProvider i18n={i18next}>
        <GlobalStyle/>
        <TenantContext.Provider value={{info}}>
          <ThemeProvider theme={{...info.theme, ...info.theme}}>
            <Switch>
              {/*Desde aqui redireccionamos a los demas modulos */}
              <Route path='/login' component={LoginPage}/>
              <Route path='/forget-password' component={ForgetPasswordPage}/>
              <Route path="/recover-password" component={RecoverPasswordPage}/>
              <Route path='/' component={Error404}/>
            </Switch>
          </ThemeProvider>
        </TenantContext.Provider>
      </I18nextProvider>
    </>
  );
}

export {TenantContext}
export default App;
