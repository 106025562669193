import {useTranslation} from "react-i18next";
import React, {useContext, useState} from "react";
import {TenantContext} from "../../App";
import FormGroup from "../../components/forms/FormGroup";
import Input from "../../components/forms/FormEl";
import Button from "../../components/forms/Button";

const SignUpComponent = () => {

  const [t] = useTranslation("global")

  const {info} = useContext(TenantContext)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [existUser, setExistUser] = useState(false)

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      //doLogin()
    }
  }

  function register() {
    if (username === "" || password === "") {
      setError('Ingrese datos correctamente');
      setExistUser(true);
    } else if (password !== password2) {
      setError('Las contraseñas deben ser iguales');
      setExistUser(true);
    }
  }

  return (
    <div style={{width: "100%"}}>
      <FormGroup>
        <label>{t("signin.user")}</label>
        <Input type='text' placeholder={t("signin.user")} value={username}
               onChange={(e) => setUsername(e.target.value)} required autoComplete="off"/>
      </FormGroup>

      <FormGroup>
        <label>{t("signin.password")}</label>
        <Input type='password' placeholder={t("signin.password")} value={password}
               onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown}/>
      </FormGroup>

      <FormGroup>
        <label>{t("signin.password2")}</label>
        <Input type='password' placeholder={t("signin.password2")} value={password2}
               onChange={(e) => setPassword2(e.target.value)} onKeyDown={handleKeyDown}/>
      </FormGroup>

      <Button variant={'secondary'}
              onClick={register}
              style={{width: '100%'}}
              disabled={loading}>{t("signin.register")}
      </Button>

      {error.length > 0 &&
      <h6 style={{color: 'red', textAlign: "center", padding: "1rem 0rem"}}>{error}</h6>
      }
    </div>
  )
}

export default SignUpComponent
