import React, {useContext, useEffect, useState} from 'react'
import styled, {keyframes} from 'styled-components'
import {TenantContext} from "../App"
import FormGroup from "../components/forms/FormGroup"
import Input from "../components/forms/FormEl";
import Button from "../components/forms/Button";
import {FaEye} from "react-icons/fa";
import {changePassword, verifyCode} from '../actions/resetPassword.action'
import Loader1 from '../components/forms/Loader1'
import queryString from "query-string";
import {Redirect} from "react-router-dom";

const change = keyframes`
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
export const LoginPageStyled = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.background};
  background-size: ${props => props.theme.bgsize};
  animation: ${change} ${props => props.theme.bganimation};
  position: relative;
`
export const ContainerLogin = styled.div`
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
`
export const WrapLogin = styled.div`
  width: 24.375rem;
  background: ${props => props.theme.bgcard};
  border-radius: 0.9rem;
  overflow: hidden;
  color: ${props => props.theme.textcolorcard};
  padding: 4rem 2rem 3rem 2rem;
  animation: 0.5s ${fadeIn} ease-out;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 50%);

  @media only screen and (max-width: 425px) {
    padding: 4rem 1rem 3rem 1rem;
  }

`
export const LoginLogo = styled.div`
  display: block;
  text-align: center;
  margin-bottom: 1rem;
`
export const LoginFormTitle = styled.span`
  display: block;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  color: ${props => props.theme.textcolorcard};
  line-height: 1.2;
  text-align: center;
  margin-bottom: 1rem;
`

const ErrorSubtitle = styled.span`
  display: flex;
  text-align: center;
  margin-bottom: 1rem;
  justify-content: center;
`

const RecoverPasswordPage = () => {
  const {info} = useContext(TenantContext)
  // const [t] = useTranslation("global")
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [valid, setValid] = useState(false)
  const [initLoading, setInitLoading] = useState(false)
  const [error, setError] = useState("")
  const [initError, setInitError] = useState("")
  const [confirm, setConfirm] = useState("")

  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);

  const queryValues = queryString.parse(window.location.search)
  const code = queryValues['code']

  useEffect(() => {
    setInitLoading(true)
    verifyCode(code).then(res => {
      if (res.status === 200) {
        setInitLoading(false)
        if (res.data.valid === false) setInitError("La solicitud de restablecimiento de contraseña expiró o ya ha sido usada")
        setValid(res.data.valid)
      }
    }).catch(() => {
      setInitLoading(false)
      setInitError("Contáctate con nuestro soporte")
    });
  },[code])


  function recoverPassword(e) {
    e.preventDefault();
    if (password.trim() === "" || password2.trim() === "") {
      setError('Ingrese datos correctamente');
    } else if (password !== password2) {
      setError('Las contraseñas deben ser iguales');
    } else {
      setError("")
      changePassword(code, password).then(res => {
        if (res.status === 200) setConfirm("La contraseña ha sido restablecida existosamente")
      }).catch(error => {
        //mostrar error de que la contra es invalida
        setError(error.response.data.message)
      })
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      recoverPassword(event)
    }
  }

  const togglePassword1 = () => {
    setPasswordShown1(!passwordShown1);
  };

  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };

  const RecoverPasswordError = ({error}) => {
    return (
      <>
        <LoginLogo>
          <img src={info.logo} alt="" width="200"/>
        </LoginLogo>
        <LoginFormTitle>
          Ooops!
        </LoginFormTitle>
        <ErrorSubtitle style={{fontSize: '1.2rem'}}>Algo salió mal en recover!</ErrorSubtitle>
        <ErrorSubtitle style={{fontSize: '0.8rem'}}>{error}</ErrorSubtitle>
      </>
    )
  }

  if (code === undefined) {
    return <Redirect to='/' />
  }

  return (
    <LoginPageStyled>
      <div style={{width: "100%", margin: "0 auto"}}>
        <ContainerLogin>
          <WrapLogin>
            {initLoading ? <Loader1/> : !valid ? <RecoverPasswordError error={initError}/> :
              <>
                <LoginLogo>
                  <img src={info.logo} alt="" width="200"/>
                </LoginLogo>
                <span style={{color: '#616161', fontSize: '20px'}}>Cree una nueva contraseña!</span>
                <form style={{width: "100%", marginTop: '0.5rem'}}>
                  <div style={{marginBottom: '0.5rem'}}>
                    <FormGroup>
                      <label>Nueva contraseña</label>
                      <div style={{position: "relative"}}>
                        <Input type={passwordShown1 ? "text" : "password"} placeholder={"Nueva contraseña"} value={password}
                               onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown}/>
                        <FaEye onClick={togglePassword1} style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "20px",
                          top: "8px",
                          width: "22px",
                          height: "23px"
                        }}/>
                      </div>
                    </FormGroup>
                  </div>
                  <div style={{marginBottom: '0.5rem'}}>
                    <FormGroup>
                      <label>Confirmar contraseña</label>
                      <div style={{position: "relative"}}>
                        <Input type={passwordShown2 ? "text" : "password"} placeholder={"Confirmar contraseña"} value={password2}
                               onChange={(e) => setPassword2(e.target.value)} onKeyDown={handleKeyDown}/>
                        <FaEye onClick={togglePassword2} style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "20px",
                          top: "8px",
                          width: "22px",
                          height: "23px"
                        }}/>
                      </div>
                    </FormGroup>
                  </div>
                  <Button variant={'primary'}
                          onClick={(e) => recoverPassword(e)}
                          style={{width: '100%', marginTop: "0.5rem"}}
                          disabled={confirm.length > 0}>
                    Restablecer contraseña
                  </Button>
                  {error.length > 0 &&
                    <h6 style={{color: 'red', textAlign: "center", padding: "1rem 0rem"}}>{error}</h6>
                  }
                  {confirm.length > 0 &&
                    <h6 style={{color: '#008000', textAlign: "center", padding: "1rem 0rem"}}>{confirm}</h6>
                  }
                </form>
              </>
            }
          </WrapLogin>
        </ContainerLogin>

      </div>
    </LoginPageStyled>
  )
}

export default RecoverPasswordPage
