import React from "react";
import styled from "styled-components";
import Button from "./Button";
import {FaGoogle} from "react-icons/fa"
import { useTranslation } from "react-i18next"

const ButtonLogin = styled(Button)`
  width: 100%;
  background: #ffffff !important;
  color: #333333;
`

function GoogleLoginButton({redirectTo, clientId}) {

  const goToGoogle = (e) => {
    e.preventDefault();
    const authorizeEndpoint = process.env.REACT_APP_BASE_URL + '/authorize'
    const state = '1022419647'

    const params = {
      connection: 'google-oauth2',
      state: state,
      redirect_uri: redirectTo,
      client_id: clientId,
      scope: 'openid profile'
    }
    const paramString = new URLSearchParams(params)
    window.location.href = `${authorizeEndpoint}?${paramString.toString()}`
  }

  const [t]=useTranslation("global")

  return (
    <div style={{marginTop: "10px", display: "flex"}}>
      <ButtonLogin onClick={goToGoogle}>
        <FaGoogle/> {t("login.login-google")}
      </ButtonLogin>
    </div>
  )
}

export default GoogleLoginButton;
