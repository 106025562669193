import React, {useContext} from 'react'
import styled, {ThemeContext} from 'styled-components'

const ButtonStyled = styled.button`
  overflow-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  border-radius: 0.3rem;
  box-shadow: rgb(0 0 0 / 16%) 0 2px 5px 0, rgb(0 0 0 / 12%) 0 2px 10px 0;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
  padding: 0.5rem 1rem;
  height: 40px;
  font-size: 0.9rem;
  display: block;
  font-weight: 600;
  background-color: ${props => props.theme.colorbutton};
  color: ${props => props.theme.textcolorbutton};

  &.btn-primary {
    background-image: linear-gradient(45deg, #0674db, #069cf9);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  }

  &.btn-primary:active {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
    transform: translate3d(0, 1px, 0);
  }

  &.btn-primary:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  }

  &.btn-secondary {
  }


  &.btn-success {
    background-image: linear-gradient(45deg, #2eaa31, #3ec234);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  }

  &.btn-small {
    min-width: 84px;
    height: 32px;
    padding: 8px;
    border-radius: 6px;
    font-size: 12px;
    line-height: 1.2;
  }

  &[disabled=disabled], &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  svg {
    padding-right: 2px;
  }
`

const Button = ({variant, size, onClick, title, children, ...props}) => {

  const themeContext = useContext(ThemeContext);

  //variant : default, primary, success
  let variantClass = ''
  let backgroundColor = ''
  if (variant === 'primary') {
    //variantClass = 'btn-primary'
  } else if (variant === 'secondary') {
    //variantClass = 'btn-secondary'
  } else if (variant == 'success') {
    //variantClass = 'btn-success'
  } else {
    //variantClass = 'btn-default'
  }

  if (size === 's') {
    variantClass = variantClass + ' btn-small'
  }

  return (
    <ButtonStyled onClick={onClick} className={variantClass} {...props}>
      {title ? title : children}
    </ButtonStyled>
  )
}

export default Button
